import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function LeaveList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "type",sortable: true, label: "Type" },
        { key: "status", sortable: true, label: "Status" },
        { key: "for", sortable: true, label: "For" },
        { key: "encashable", sortable: true, label: "Cashable" },
        { key: "is_unlimited", sortable: true, label:"Balance Level" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalLeaveList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const leavesMetaData = ref([]);
    const leaveStatusOption = ref([]);
    const leaveCashableFilter = ref(null);
    const leaveCashableOption = ref([]);
    const leaveForFilter = ref(null);
    const leaveForOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalLeaveList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
            leaveCashableFilter,
            leaveForFilter
        ],
        () => {
            
            refetchData();
        }
    );

    const fetchLeaveList = (ctx, callback) => {
        store
            .dispatch("app-leaves/fetchLeaves", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                leaveCashableFilter: leaveCashableFilter.value,
                leaveForFilter: leaveForFilter.value

            })
            .then(response => {
                const leave = response.data.data;
                const { total } = response.data.meta;
                leaveStatusOption.value = response.data.status;
                leaveCashableOption.value = response.data.leaveCashableOption;
                leaveForOption.value = response.data.leaveForOption;

                callback(leave);
                totalLeaveList.value = total;
                leavesMetaData.value = leave;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching leaves",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error fetching leaves`,
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                        },
                        {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                        })
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchLeaveList,
        tableColumns,
        perPage,
        currentPage,
        totalLeaveList,
        leavesMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        leaveStatusOption,
        statusFilter,
        leaveCashableOption,
        leaveCashableFilter,
        leaveForOption,
        leaveForFilter
    };
}
